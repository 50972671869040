<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="700px"
    overlay-opacity="0.9"
    @click:outside="close"
    persistent
  >
    <v-card class="py-4">
      <v-card-text>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <slot></slot>
          </div>
        </template>
        <div class="d-flex gap-2 align-center mb-3">
          <base-avatar
            :seed="item.Ticket.Owner.id"
            :src="item.Ticket.Owner.photo"
            :size="50"
          />
          <div>
            <h6 class="mb-0">{{ item.Ticket.Owner.name }}</h6>
            <a @click="openWhatsapp">
              {{ item.Ticket.Owner.phone }}
            </a>
          </div>
          <v-spacer />
          <v-btn @click="openWhatsapp" fab color="success" x-small>
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </div>
        <v-card outlined class="pa-4 mb-2">
          <div
            class="d-flex align-end mb-1 justify-space-between flex-wrap-reverse"
          >
            <h6 class="font-weight-bold mb-0">Motivo</h6>
            <div class="text-end">
              <p class="mb-0 lh-1 font-weight-medium">
                Solicitado em: {{ item.createdAt | date("DD/MM/YY HH:mm") }}
              </p>
              <small class="lh-1">
                {{ [item.Ticket.Payment.payedAt, item.createdAt] | dateDiff }}
                após o pagamento
              </small>
            </div>
          </div>
          {{ item.reason }}
        </v-card>
        <v-card outlined class="pa-4 mb-4">
          <h6 class="font-weight-bold mb-1">Compra</h6>
          <div class="d-flex flex-wrap gap-x-6 gap-y-3 justify-space-around">
            <div class="text-center">
              <p class="mb-0 lh-1">Data do Pagamento</p>
              <p class="mb-0 font-weight-bold">
                {{ item.Ticket.Payment.payedAt | date("DD/MM/YY HH:mm") }}
              </p>
            </div>
            <div class="text-center">
              <p class="mb-0 lh-1">Vendido por</p>
              <template v-if="!item.Ticket.Seller">Online</template>
              <template v-else>
                <base-avatar
                  :seed="item.Ticket.Seller.id"
                  :src="item.Ticket.Seller.photo"
                  :size="20"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{
                  item.Ticket.Seller.name || "Conta não criada"
                }}</span>
              </template>
            </div>

            <div class="text-center">
              <p class="mb-0 lh-1">Meio de Pagamento</p>
              <p class="mb-0 font-weight-bold">
                {{
                  PAYMENT.paymentMethod[item.Ticket.Payment.paymentMethod].text
                }}
              </p>
            </div>
            <div class="text-center">
              <p class="mb-0 lh-1">Forma de Pagamento</p>
              <p class="mb-0 font-weight-bold">
                {{ PAYMENT.paymentType[item.Ticket.Payment.paymentType].text }}
              </p>
            </div>
            <div class="text-center">
              <p class="mb-0 lh-1">Status do Pagamento</p>
              <v-chip
                x-small
                label
                :color="PAYMENT.ticketStatus[item.Ticket.Payment.status].color"
              >
                {{ PAYMENT.ticketStatus[item.Ticket.Payment.status].text }}
              </v-chip>
            </div>
          </div>
        </v-card>
        <v-alert
          v-if="item.Ticket.Payment.Ticket.length > 1"
          type="warning"
          text
          dense
        >
          Esse pagamento possui
          <strong>{{ item.Ticket.Payment.Ticket.length }} ingressos</strong>
          vinculados, todos serão cancelados ao reembolsar.
        </v-alert>
        <v-card outlined class="pa-4 mb-2" v-if="item.ClosedBy">
          <h6 class="font-weight-bold mb-1">Solicitação encerrada por</h6>
          <div class="d-flex gap-2 my-2">
            <base-avatar
              :seed="item.ClosedBy.id"
              :src="item.ClosedBy.photo"
              :size="50"
            />
            <div>
              <h6 class="mb-0 text-14">{{ item.ClosedBy.name }}</h6>
              <span> em {{ item.closedAt | date("DD/MM/YYYY HH:mm") }} </span>
            </div>
          </div>
          <h6 class="mb-0 text-14 my-2">Resposta</h6>
          <p class="mb-0">{{ item.answer }}</p>
        </v-card>

        <v-alert type="error" v-if="error">
          {{ error }}
        </v-alert>

        <div class="d-flex flex-wrap gap-2">
          <v-btn
            v-if="item.Ticket.Payment.status != 'refunded'"
            @click="refundTicket"
            class="flex-grow-1"
            color="primary"
            :disabled="loading"
            :outlined="confirmingRefund"
          >
            Reembolsar
          </v-btn>
          <v-btn
            v-if="!item.closedAt"
            @click="declineDialog"
            class="flex-grow-1"
            color="error"
            :loading="loading"
          >
            <template> Recusar </template>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <decline-refund :item="item" @refresh="refresh" />
  </v-dialog>
</template>

<script>
import PAYMENT from "@/utils/payment";
import DeclineRefund from "../party/modal/DeclineRefund.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: { DeclineRefund },
  data() {
    return {
      PAYMENT,
      confirmingRefund: false,
      loading: false,
      error: null,
      dialogVisible: true, // Alterado para true para abrir a modal
    };
  },
  methods: {
    refresh() {
      this.close();
      this.$emit("refresh");
    },
    declineDialog() {
      this.$root.$emit("decline-refund", this.item);
    },
    confirmRefund() {
      if (!this.confirmingRefund) {
        this.confirmingRefund = true;
        setTimeout(() => {
          this.confirmingRefund = false;
        }, 3000);
      } else {
        this.closeRefoundRequest();
      }
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      if (this.loading) return;
      this.dialogVisible = false;
      this.$emit("close");
      this.$nextTick(() => {
        this.whatsapp = "";
        this.ddi = 55;
        this.check = false;
        this.error = false;
      });
    },
    refundTicket() {
      console.log("refund-ticket", this.item.Ticket.Payment);
      this.$root.$emit("refund-ticket", this.item.Ticket.Payment);
    },
    openWhatsapp() {
      const phone =
        `${this.item.Ticket.Owner.ddi}${this.item.Ticket.Owner.phone}`.replace(
          /\D/g,
          ""
        );

      window.open(
        `https://wa.me/${phone}?text=Olá ${
          this.item.Ticket.Owner.name.split(" ")[0]
        }%0A`
      );
    },
  },
  mounted() {
    this.$root.$on("refund-details", this.open);
    this.$root.$on("refund-ticket:success", this.close);
  },
  beforeDestroy() {
    this.$root.$off("refund-details", this.open);
  },
};
</script>

<style scoped></style>
