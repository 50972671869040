<template>
  <v-dialog
    v-model="dialog"
    max-width="1100px"
    overlay-opacity="0.9"
    scrollable
    :persistent="innerLoading"
    eager
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between align-start">
        <span>Vendas de Assentos</span>
        <v-btn
          @click="exportToExcel"
          :disabled="innerLoading"
          color="primary"
          x-small
          :loading="loadingExport"
        >
          <!-- excel icon -->
          <v-icon left x-small>mdi-file-excel</v-icon>
          Exportar
        </v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-column gap-3">
        <v-row>
          <v-col cols="12" sm="6" md="7">
            <table-selector
              v-if="tableMap"
              :tableMap="tableMap"
              :party="data.party"
              :loading="innerLoading"
              colorMode="available"
              :statusGetter="(table) => table.status"
            />
            <!-- {{ tableMap }} -->
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="group in tableMap?.Groups"
                :key="group.id"
              >
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <v-avatar :color="group.color" class="mr-2" size="12" />
                    {{ group.name }}
                  </div>

                  <div class="d-flex align-center justify-end gap-1">
                    {{ soldPercentagePerGroup[group.id]?.sold }}/
                    {{ soldPercentagePerGroup[group.id]?.total }}
                    <v-progress-circular
                      size="14"
                      :value="soldPercentagePerGroup[group.id]?.percentage"
                      color="primary"
                    />
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-alert
                    v-if="!groups[group.id]"
                    type="warning"
                    class="mb-1"
                    text
                  >
                    Nenhum ingresso vendido
                  </v-alert>
                  <v-data-table
                    v-else
                    :headers="[
                      { text: 'Ingresso', value: 'TicketBlock.name' },
                      { text: 'Proprietário', value: 'Owner.name' },
                      { text: group.name, value: 'Table.name' },
                    ]"
                    :items="groups[group.id].tickets"
                    :items-per-page="10"
                    :hide-default-footer="groups[group.id].tickets.length <= 10"
                    dense
                    group-by="Table.name"
                  >
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="innerLoading" block text @click="dialog = false">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TABLE from "@/services/admin/party/table";
import TableSelector from "../../../../global/partyTable/TableSelector.vue";
import tablesSales from "@/utils/exportExcel/tablesSales";

export default {
  name: "TableSalesModal",
  components: { TableSelector },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    groups: {
      type: Object,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    innerLoading: false,
    tableMap: null,
    loadingExport: false,
  }),
  computed: {
    soldPercentagePerGroup() {
      return Object.fromEntries(
        this.tableMap?.Groups.map((group) => {
          const total = group.Tables.length;
          const sold = group.Tables.filter(
            (table) => table.status == "sold"
          ).length;
          return [
            group.id,
            {
              total,
              sold,
              percentage: (sold / total) * 100,
            },
          ];
        }) || []
      );
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.getTableMap();
    },
    async getTableMap() {
      try {
        this.innerLoading = true;
        const { tableMap } = await TABLE.get(
          this.data.party.organizationId,
          this.data.party.id
        );
        this.tableMap = tableMap || { backgroundUrl: null, Groups: [] };
      } catch (error) {
        console.error(error);
        this.error = error.message || "Erro ao carregar avaliações";
      } finally {
        this.innerLoading = false;
      }
    },
    async exportToExcel() {
      this.loadingExport = true;
      try {
        await tablesSales({
          party: this.data.party,
          tableMap: this.tableMap,
          groups: this.groups,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingExport = false;
      }
    },
  },
};
</script>

<style></style>
