<template>
  <v-expansion-panels
    v-model="expanded"
    focusable
    :popout="$vuetify.breakpoint.mdAndUp"
    class="mt-4 outlined elevation-0"
  >
    <draggable
      v-model="tGroups"
      handle=".drag-handle"
      @end="onEndTicketGroup"
      class="w-full"
      @start="expanded = null"
    >
      <v-expansion-panel
        v-for="(tGroup, i) in tGroups"
        :key="tGroup.id"
        class="elevation-0 rounded-tl"
        :style="
          tGroup.color
            ? `box-shadow: ${tGroup.color} 6px 0px 0px 0px inset !important`
            : undefined
        "
      >
        <v-expansion-panel-header
          class="pl-2"
          :class="expanded === i ? 'pt-8' : ''"
        >
          <v-progress-linear
            class="rounded-b-0"
            :height="22"
            style="position: absolute; top: 0; left: 0; animation: normal"
            :style="{ 'max-height': expanded === i ? '100%' : '5px' }"
            :value="tGroup.quantity.percentage"
            :rounded="expanded === i || i === 0"
          >
            <v-scroll-y-transition leave-absolute>
              <b class="text-14 ma-0" v-if="expanded === i">
                {{ tGroup.quantity.total - tGroup.quantity.remaining }} de
                {{ tGroup.quantity.total }} ingresso{{
                  tGroup.quantity.total != 1 ? "s" : ""
                }}
                vendido{{ tGroup.quantity.total != 1 ? "s" : "" }}
              </b>
            </v-scroll-y-transition>
          </v-progress-linear>
          <span class="font-weight-bold">
            <v-btn
              icon
              small
              class="drag-handle"
              style="cursor: grab !important"
            >
              <v-icon>mdi-drag</v-icon>
            </v-btn>

            <span class="mr-2 lh-3">{{ tGroup.name }}</span>
            <v-scroll-y-transition>
              <v-chip
                v-if="tGroup.maxTickets && party.maxTickets"
                class="mr-2 font-weight-medium"
                x-small
              >
                {{ tGroup.maxTickets }} por pessoa
              </v-chip>
            </v-scroll-y-transition>
            <v-scroll-y-transition>
              <v-chip
                v-if="
                  tGroup.ageGroup != null && tGroup.ageGroup != party.ageGroup
                "
                class="mr-2 font-weight-medium"
                x-small
              >
                {{ tGroup.ageGroup }} anos
              </v-chip>
            </v-scroll-y-transition>
            <v-scroll-y-transition>
              <v-chip
                v-if="tGroup.requireBiometry"
                class="mr-2 font-weight-medium"
                x-small
              >
                <v-icon x-small left>mdi-face-recognition</v-icon>
                Biometria facial
              </v-chip>
            </v-scroll-y-transition>

            <v-scroll-y-transition>
              <v-chip
                v-if="tGroup.tableGroup && expanded !== i"
                class="mr-2 font-weight-medium"
                x-small
              >
                <v-icon x-small left> mdi-table-chair </v-icon>
                {{ tGroup.tableGroup.name }}
              </v-chip>
            </v-scroll-y-transition>
            <v-scroll-y-transition>
              <v-chip
                v-if="tGroup.WhatsappGroups.length && expanded !== i"
                class="mr-2 font-weight-medium"
                x-small
              >
                <v-icon x-small left>mdi-shield-alert</v-icon>
                com restrição
              </v-chip>
            </v-scroll-y-transition>
          </span>
          <v-spacer />
          <div
            class="d-flex align-center justify-space-between mx-2 flex-grow-0 gap-2"
          >
            <span class="text--disabled caption ma-0">
              {{ tGroup.TicketBlock.length }} lote{{
                tGroup.TicketBlock.length != 1 ? "s" : ""
              }}
            </span>
            <v-btn
              v-if="expanded === i"
              :icon="$vuetify.breakpoint.mdAndDown"
              small
              :color="$vuetify.breakpoint.mdAndDown ? '' : 'primary'"
              @click.stop="addGroup(tGroup)"
            >
              <v-icon small :left="!$vuetify.breakpoint.mdAndDown">
                mdi-pencil
              </v-icon>
              {{ $vuetify.breakpoint.mdAndDown ? "" : "Setor" }}
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2">
          <v-alert
            type="warning"
            text
            dense
            v-if="tGroup.WhatsappGroups.length"
            icon="mdi-shield-alert"
          >
            <p class="mb-0 text-14">
              A venda online deste setor está restrita a membros dos seguintes
              grupos do WhatsApp:
            </p>
            <v-chip
              v-for="group in tGroup.WhatsappGroups"
              :key="group.id"
              class="ma-1 pl-0"
            >
              <div class="d-flex gap-2 align-center">
                <v-avatar
                  style="width: 32px !important; height: 32px !important"
                >
                  <v-img
                    v-if="group.image"
                    width="32px"
                    height="32px"
                    :src="group.image"
                  />
                  <v-icon v-else small class="pl-2">mdi-account-group</v-icon>
                </v-avatar>
                {{ group.name }}
              </div>
            </v-chip>
          </v-alert>
          <v-alert
            v-if="party.active && tGroup.tableGroup"
            color="info"
            text
            dense
          >
            <div class="d-flex flex-wrap gap-3 align-center">
              <div class="flex-grow-1 d-flex align-center gap-3">
                <v-icon color="info">mdi-table-chair</v-icon>
                <div>
                  <p class="mb-0 text-14">
                    Os ingressos deste setor serão alocados no grupo
                    {{ tGroup.tableGroup.name }}
                  </p>
                  <small>
                    Caso não haja assentos disponíveis, o setor não poderá ser
                    vendido.
                  </small>
                </div>
              </div>
              <div outlined class="text-center flex-grow-1 flex-shrink-0">
                <p class="mb-0 lh-1 font-weight-bold">
                  {{ tGroup.tableGroup.capacity }}
                </p>
                <small class="lh-1">Capacidade</small>
              </div>
              <div outlined class="text-center flex-grow-1 flex-shrink-0">
                <p class="mb-0 lh-1 font-weight-bold">
                  {{ tGroup.tableGroup.availableTables.length }} /
                  {{ tGroup.tableGroup.Tables.length }}
                </p>
                <small class="lh-1">Disponiveis</small>
              </div>
            </div>
          </v-alert>
          <div class="flex-grow-1 flex-shrink-0">
            <base-copier
              v-if="config.opMode"
              type="chip"
              :value="tGroup.id || ''"
              hide-icon
            >
              <template v-slot:default="{ copy, icon }">
                <v-chip
                  @click="copy"
                  x-small
                  label
                  class="text-overline font-weight-medium pl-1 text-0"
                >
                  #{{ tGroup.id | shortId }}
                  <v-icon right x-small>{{ icon }}</v-icon>
                </v-chip>
              </template>
            </base-copier>
            <h6 class="">Descrição</h6>
            {{ tGroup.description || "Sem descrição" }}
          </div>
          <v-divider class="my-4" />
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center gap-2">
              <h6 class="mb-0">Lotes</h6>
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon v-bind="attrs" v-on="on">
                      <v-icon small>mdi-sort</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in filterOptions"
                      :key="index"
                      @click="sortTicketBlock(tGroup, item.params)"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div v-if="party.active" class="mb-2">
              <v-btn
                v-if="config.opMode"
                class="ml-1"
                color="primary"
                icon
                @click="pasteTicketBlock(tGroup)"
                :small="$vuetify.breakpoint.mdAndDown"
              >
                <v-icon small>mdi-content-paste</v-icon>
              </v-btn>
              <v-template v-if="tGroup.TicketBlock.length">
                <v-btn
                  v-if="!tGroup.KitList.length"
                  class="ml-1"
                  color="primary"
                  text
                  @click="addKit({ ticketGroupId: tGroup.id })"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  <v-icon small left>mdi-plus</v-icon>
                  Kit
                </v-btn>
                <v-btn
                  v-if="config.opMode"
                  color="primary"
                  class="mr-1"
                  icon
                  @click="addMultiCreateBlocks({ ticketGroup: tGroup.id })"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  <v-icon small>mdi-expand-all-outline</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  @click="addBlock({ ticketGroup: tGroup.id })"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  <v-icon small>mdi-plus</v-icon>
                  Lote
                </v-btn>
              </v-template>
            </div>
            <v-alert
              v-else
              dense
              type="warning"
              text
              border="left"
              class="mb-0"
            >
              Não é possível editar lotes de um evento inativo ou encerrado.
            </v-alert>
          </div>
          <v-data-iterator
            :items="tGroup.TicketBlock"
            :key-field="(tBlock) => tBlock.id"
            :footer-props="{ itemsPerPageOptions: [12, 24, 48] }"
            :items-per-page="12"
            :hide-default-footer="tGroup.TicketBlock.length <= 12"
            :search="blockSearch"
            :custom-filter="filterFunc"
          >
            <template v-slot:header>
              <v-text-field
                v-if="tGroup.TicketBlock.length > 12"
                v-model="blockSearch"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                outlined
                dense
              ></v-text-field>
            </template>

            <template v-slot:no-results>
              <v-alert
                :value="true"
                color="info"
                icon="mdi-information"
                text
                dense
                class="mb-0 mt-2"
              >
                Nenhum lote encontrado para a pesquisa.
              </v-alert>
            </template>

            <template v-slot:no-data>
              <v-alert :value="true" color="info" text dense class="mb-0 mt-2">
                Nenhum lote criado para este setor.
                <div class="pt-3 d-flex flex-column flex-md-row gap-1">
                  <v-btn
                    class="ml-1"
                    color="primary"
                    @click="addBlock({ ticketGroup: tGroup.id })"
                    :small="$vuetify.breakpoint.mdAndDown"
                  >
                    <v-icon small left>mdi-plus</v-icon>
                    Lote
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="ml-1"
                    color="primary"
                    text
                    @click="addMultiCreateBlocks({ ticketGroup: tGroup.id })"
                    :small="$vuetify.breakpoint.mdAndDown"
                  >
                    <v-icon small left>mdi-expand-all-outline</v-icon>
                    Múltiplos Lotes
                  </v-btn>
                  <v-btn
                    v-if="config.opMode"
                    class="ml-1"
                    color="primary"
                    text
                    @click="createMultipleKit({ ticketGroupId: tGroup.id })"
                    :small="$vuetify.breakpoint.mdAndDown"
                  >
                    <v-icon small left>mdi-plus-box-multiple</v-icon>
                    Múltiplos Kits
                  </v-btn>
                </div>
              </v-alert>
            </template>

            <template v-slot="{ items }">
              <v-row class="mx-0">
                <draggable
                  class="row"
                  v-model="items"
                  group="tGroup"
                  handle=".drag-handleBlock"
                  @end="onEnd"
                >
                  <v-col
                    v-for="ticketBlock in items"
                    :key="ticketBlock.id"
                    cols="12"
                    sm="6"
                    lg="4"
                    xl="3"
                  >
                    <ticket-block-management
                      :ticketBlock="ticketBlock"
                      :party="party"
                      :selectionMode="selectionMode"
                      :isSelected="
                        selectedTicketBlocks.some(
                          (tb) => tb.id === ticketBlock.id
                        )
                      "
                      @toggleSelection="toggleTicketBlockSelection"
                    />
                  </v-col>
                </draggable>
              </v-row>
            </template>
          </v-data-iterator>
          <div v-if="tGroup.KitList.length" class="mt-4">
            <v-divider class="my-4" />
            <div class="d-flex align-center justify-space-between mb-2">
              <h6 class="mb-0">Kits</h6>
              <div v-if="party.active">
                <v-btn
                  v-if="config.opMode"
                  class="ml-1"
                  color="primary"
                  icon
                  @click="pasteKit(tGroup)"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  <v-icon small>mdi-content-paste</v-icon>
                </v-btn>
                <v-btn
                  v-if="config.opMode"
                  class="ml-1"
                  color="primary"
                  icon
                  @click="createMultipleKit({ ticketGroupId: tGroup.id })"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  <v-icon small>mdi-plus-box-multiple</v-icon>
                </v-btn>
                <v-btn
                  class="ml-1"
                  color="primary"
                  @click="addKit({ ticketGroupId: tGroup.id })"
                  :small="$vuetify.breakpoint.mdAndDown"
                >
                  <v-icon small left>mdi-plus</v-icon>
                  Kit
                </v-btn>
              </div>
              <v-alert
                v-else
                dense
                type="warning"
                text
                border="left"
                class="mb-0"
              >
                Não é possível editar kits de um evento inativo ou encerrado.
              </v-alert>
            </div>
            <v-data-iterator
              :items="tGroup.KitList"
              :key-field="(tBlock) => tBlock.id"
              :footer-props="{ itemsPerPageOptions: [12, 24, 48] }"
              :items-per-page="12"
              :hide-default-footer="tGroup.KitList.length <= 12"
              :search="kitSearch"
              :custom-filter="filterFunc"
            >
              <template v-slot:header>
                <v-text-field
                  v-if="tGroup.KitList.length > 12"
                  v-model="kitSearch"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  single-line
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </template>

              <template v-slot:no-results>
                <v-alert
                  :value="true"
                  color="info"
                  icon="mdi-information"
                  text
                  dense
                  class="mb-0 mt-2"
                >
                  Nenhum kit encontrado para a pesquisa.
                </v-alert>
              </template>

              <template v-slot="{ items }">
                <v-row>
                  <v-col
                    v-for="kit in items"
                    :key="kit.id"
                    cols="12"
                    sm="6"
                    lg="4"
                    xl="3"
                  >
                    <kit-management :kit="kit" :party="party" />
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </draggable>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";
import TicketBlockManagement from "./TicketBlockManagement";
import KitManagement from "./KitManagement";
import draggable from "vuedraggable";
import TICKET from "@/services/admin/ticket";

export default {
  components: { TicketBlockManagement, KitManagement, draggable },
  data: () => ({
    expanded: null,
    blockSearch: "",
    kitSearch: "",
    tGroups: [],
    filterOptions: [
      {
        title: "Maior preço",
        value: "best-sellers",
        icon: "mdi-sort-numeric-descending",
        params: ["price", 1],
      },
      {
        title: "Menor preço",
        value: "less-sellers",
        icon: "mdi-sort-numeric-ascending",
        params: ["price", -1],
      },
      {
        title: "Nomes A-Z",
        value: "name-asc",
        icon: "mdi-sort-alphabetical-ascending",
        params: ["name", -1],
      },
      {
        title: "Nomes Z-A",
        value: "name-desc",
        icon: "mdi-sort-alphabetical-descending",
        params: ["name", 1],
      },
    ],
  }),
  methods: {
    onEnd(event) {
      const oldIndex = event.oldIndex;
      const newIndex = event.newIndex;
      const tGroup = this.tGroups[this.expanded];

      const item = tGroup.TicketBlock[oldIndex];
      tGroup.TicketBlock.splice(oldIndex, 1);
      tGroup.TicketBlock.splice(newIndex, 0, item);
      this.saveNewOrder();
    },
    onEndTicketGroup(event) {
      const { oldIndex, newIndex } = event;

      if (oldIndex !== newIndex) {
        this.$emit("updateTicketGroupOrder", {
          oldIndex,
          newIndex,
        });
      }
    },
    toggleTicketBlockSelection(ticketBlock) {
      this.$emit("toggleTicketBlockSelection", ticketBlock);
    },
    sortTicketBlock(tGroup, params) {
      tGroup.TicketBlock.sort((a, b) => {
        if (a[params[0]] < b[params[0]]) return params[1];
        if (a[params[0]] > b[params[0]]) return -params[1];
        return 0;
      });
      this.saveNewOrder();
    },
    async saveNewOrder() {
      try {
        this.loading = true;
        const orgId = this.party.organizationId;
        const partyId = this.party.id;
        const ticketGroupId = this.tGroups[this.expanded].id;

        const data = {
          order: this.tGroups[this.expanded].TicketBlock.map(
            (tBlock) => tBlock.id
          ),
          ticketGroupId: ticketGroupId,
        };

        await TICKET.reorderTicketBlock(orgId, partyId, data);
      } catch (error) {
        console.error("Failed to update order:", error);
      } finally {
        this.loading = false;
      }
    },
    addGroup(tGroup) {
      this.$root.$emit("ticket-group-modal", tGroup);
    },
    addBlock(obj) {
      this.$root.$emit("ticket-block-modal", obj);
    },
    addMultiCreateBlocks(obj) {
      this.$root.$emit("ticket-multi-blocks-modal", obj);
    },
    addKit(obj) {
      this.$root.$emit("kit-modal", obj);
    },
    createMultipleKit(obj) {
      this.$root.$emit("kit-multiple-modal", obj);
    },

    filterFunc(items, search) {
      return items.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      });
    },
    async getClipboard() {
      try {
        var clipboard = window.sessionStorage.getItem("copy-op");
        if (!clipboard) clipboard = await navigator.clipboard.readText();
        return JSON.parse(clipboard);
      } catch (e) {
        throw new Error("Não foi possível acessar a área de transferência");
      }
    },

    async pasteTicketBlock(tGroup) {
      try {
        var clipboard = await this.getClipboard();

        if (clipboard._copyType !== "ticket-block")
          throw new Error("Não é um lote");

        this.$root.$emit("ticket-block-modal", {
          ...clipboard,
          ticketGroup: tGroup.id,
        });
      } catch (e) {
        alert(e.message);
      }
    },
    async pasteKit(tGroup) {
      try {
        var clipboard = await this.getClipboard();

        if (clipboard._copyType !== "kit") throw new Error("Não é um kit");

        this.$root.$emit("kit-modal", {
          ...clipboard,
          ticketGroupId: tGroup.id,
        });
      } catch (e) {
        alert(e.message);
      }
    },
    reduceTicketGroups() {
      this.tGroups = this.ticketGroups.map((tGroup) => {
        const ticketBlock = tGroup.TicketBlock.filter(
          (tBlock) => this.config.opMode || !tBlock.KitItem.length
        );

        const remaining = tGroup.TicketBlock.reduce(
          (abb, tBlock) => {
            abb.total += tBlock.quantity;
            abb.remaining += tBlock.tickets.remaining;
            return abb;
          },
          { total: 0, remaining: 0 }
        );

        remaining.percentage =
          ((remaining.total - remaining.remaining) / remaining.total) * 100;

        const tableGroup =
          tGroup.tableGroupId && this.tableMap?.Groups
            ? this.tableMap.Groups.find((g) => g.id === tGroup.tableGroupId)
            : null;

        return {
          ...tGroup,
          TicketBlock: ticketBlock,
          quantity: remaining,
          tableGroup: tableGroup
            ? {
                ...tableGroup,
                availableTables: tableGroup.Tables.filter((t) => !t.Ticket),
              }
            : null,
        };
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["config"]),
    currentTicketGroupId() {
      if (this.expanded != null && this.tGroups[this.expanded]) {
        return this.tGroups[this.expanded].id;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.$root.$on(
      "ticket-block-order-updated",
      this.handleTicketBlockOrderUpdated
    );
    this.reduceTicketGroups();
    if (this.ticketGroups.length == 1) this.expanded = 0;
  },
  watch: {
    expanded(newVal) {
      this.kitSearch = "";
      this.blockSearch = "";
      const currentId = this.currentTicketGroupId;
      this.$emit("current-ticket-group-changed", currentId);
    },
    ticketGroups: {
      handler() {
        this.reduceTicketGroups();
      },
      deep: true,
    },
    tableMap: {
      handler() {
        this.reduceTicketGroups();
      },
      deep: true,
    },
    "config.opMode"() {
      this.reduceTicketGroups();
    },
  },
  props: {
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    selectionMode: {
      type: Boolean,
      default: false,
    },
    selectedTicketBlocks: {
      type: Array,
      default: () => [],
    },
    party: {
      type: Object,
      default: () => ({}),
    },
    tableMap: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
