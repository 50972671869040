<template>
  <div>
    <v-dialog v-model="dialogVisible" max-width="600" :persistent="loading">
      <v-card>
        <v-card-title>Recusar reembolso</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="reason"
            label="Motivo"
            outlined
            rows="5"
            :rules="[(v) => !!v || 'Campo obrigatório']"
          ></v-textarea>
          <div>
            <v-chip-group
              column
              v-model="selectedAnswer"
              dense
              color="primary"
              class="d-flex justify-center mb-3"
            >
              <v-chip v-for="item in optionsAnswer" :key="item.title">
                <span>{{ item.title }}</span>
              </v-chip>
            </v-chip-group>
          </div>
          <v-switch
            hide-details
            class="mt-0"
            label="Notificar usuário"
            v-model="notifyUser"
          />
          <v-alert v-if="error" type="error" class="mt-6 mb-0" text>
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="justify-space-between">
          <v-btn @click="close" :disabled="loading" text>Cancelar</v-btn>
          <v-btn
            @click="closeRefoundRequest"
            color="primary"
            :disabled="!reason"
            :loading="loading"
            >Recusar Reembolso</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import PARTY from "@/services/admin/party";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialogVisible: false,
    reason: "",
    notifyUser: true,
    selectedAnswer: null,
    error: null,
    optionsAnswer: [
      {
        title: "Fora do prazo",
        body: "Prezado(a) Cliente,\n\nLamentamos informar que sua solicitação de reembolso para a compra realizada em {data_da_compra} não pode ser processada. De acordo com nossa política, reembolsos devem ser solicitados dentro de um prazo de 7 dias após a compra e 48 horas antes do evento. Sua solicitação foi recebida em {data_da_solicitação_de_reembolso}, que infelizmente está fora deste período. Agradecemos a compreensão.",
      },
      {
        title: "Ingresso Usado",
        body: "Prezado(a) Cliente,\n\nVerificamos que o(s) ingresso(s) comprados em {data_da_compra} já foi(ram) utilizado(s) para acessar o evento. Infelizmente, uma vez que o ingresso é usado, ele não é elegível para reembolso. Agradecemos sua compreensão e esperamos vê-lo em futuros eventos.",
      },
      {
        title: "Ingresso de Terceiros",
        body: "Prezado(a) Cliente,\n\nSua solicitação de reembolso para o ingresso adquirido em {data_da_compra} foi revisada. Identificamos que esta compra foi realizada através de um canal não oficial. Nossa política de reembolso só se aplica a ingressos comprados diretamente por nossos canais autorizados. Recomendamos sempre a utilização de nossos canais oficiais para garantir sua segurança e direitos.",
      },
      {
        title: "Motivos Pessoais",
        body: "Prezado(a) Cliente,\n\nEntendemos que imprevistos acontecem e lamentamos que você não possa participar do evento. No entanto, conforme nossas políticas, cancelamentos por razões pessoais não são elegíveis para reembolso. Agradecemos a compreensão e esperamos que possa nos acompanhar em futuras oportunidades.",
      },
      {
        title: "Problemas Técnicos do Usuário",
        body: "Prezado(a) Cliente,\n\nRecebemos sua solicitação de reembolso referente a dificuldades técnicas para acessar seu ingresso digital para o evento. Verificamos que o problema está relacionado ao dispositivo ou conexão utilizados, o que infelizmente não se enquadra em nossa política de reembolso. Recomendamos verificar a compatibilidade de seu dispositivo ou conexão para futuras compras.",
      },
    ],
  }),
  methods: {
    open() {
      this.dialogVisible = true;
      this.notifyUser = true;
      this.selectedAnswer = null;
      this.reason = "";
    },
    close() {
      if (this.loading) return;
      this.dialogVisible = false;
      this.$emit("close");
    },
    async closeRefoundRequest() {
      try {
        this.loading = true;
        this.error = null;
        await PARTY.refund.closeRefoundRequest(
          this.item.Ticket.TicketBlock.TicketGroup.Party.organizationId,
          this.item.Ticket.TicketBlock.TicketGroup.Party.id,
          this.item.id,
          { answer: this.reason, notify: this.notifyUser }
        );
        this.dialogVisible = false;
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao recusar reembolso";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    selectedAnswer(value) {
      if (value !== null) {
        const option = this.optionsAnswer[value];
        const variables = {
          data_da_compra: moment(this.item.Ticket.Payment.payedAt).format(
            "DD/MM/YY HH:mm"
          ),
          data_da_solicitação_de_reembolso: moment(this.item.createdAt).format(
            "DD/MM/YY HH:mm"
          ),
        };
        var body = option.body;
        for (const key in variables) {
          body = body.replace(new RegExp(`{${key}}`, "g"), variables[key]);
        }
        this.reason = body;
      }
    },
  },
  mounted() {
    this.$root.$on("decline-refund", this.open);
  },
};
</script>

<style lang="scss" scoped></style>
